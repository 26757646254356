import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Prelaunch from './components/layout/Prelaunch';
import Detail from './components/layout/Instruction';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Prelaunch />} />
        <Route path="/detail/:userType" element={<Detail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
