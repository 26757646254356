import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Prelaunch = () => {
  const navigate = useNavigate();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const users = [
    {
      name: '商家',
      description: '我是商家，我想要找更便宜和更安全的仓库',
      buttonText: '我要找仓库'
    },
    {
      name: '仓库',
      description: '我是仓库主，我想要要入驻并更轻松无痛得找到商家',
      buttonText: '注册成为仓库'
    },
  ];

  const handleUserClick = async (userType) => {
    setIsTransitioning(true);
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate(`/detail/${userType.toLowerCase()}`);
    }, 500); // 500ms matches the transition duration
  };

  return (
    <div 
      className={`min-h-screen 
                  bg-cover bg-center bg-no-repeat 
                  bg-black/50
                  text-white
                  font-sans
                  transition-opacity duration-500 ease-in-out
                  ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}
      style={{
        backgroundImage: `url("${isMobile ? '/images/background_mobile.jpg' : '/images/background_m.jpg'}")`,
        backgroundBlendMode: 'overlay'
      }}
    >
     {/* Navigation */}
    <nav className="flex justify-between items-center p-8 container mx-auto px-16">
    <div className="flex items-center space-x-4">
        <img 
            src="/images/Logo_White.svg" 
            alt="SSEEAA Logo" 
            className="w-32 h-32 cursor-pointer" 
            onClick={() => navigate('/')}
        />
    </div>
    
    {/* Mobile Menu Button */}
    <button 
      className="md:hidden z-50"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
    >
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        {isMenuOpen ? (
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        ) : (
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
        )}
      </svg>
    </button>

    {/* Desktop Menu */}
    <div className="hidden md:flex space-x-8">
      <a href="/intro" className="hover:text-gray-300 font-bold">简介</a>
      <a href="/team" className="hover:text-gray-300 font-bold">团队</a>
      <a href="https://www.xiaohongshu.com/user/profile/6712b033000000001d020291?xsec_token=&xsec_source=pc_search" 
         className="hover:text-gray-300 font-bold">联系</a>
    </div>

  {/* Mobile Menu Overlay */}
  <div 
    className={`fixed inset-0 bg-black bg-opacity-50 md:hidden transition-opacity duration-300 ${isMenuOpen ? 'opacity-100 visible z-40' : 'opacity-0 invisible'}`}
    onClick={() => setIsMenuOpen(false)}
  >
    <div 
      className={`fixed right-0 top-0 h-full w-64 bg-gray-900 transform transition-transform duration-300 ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} z-50`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-col items-center pt-20 space-y-8">
        <a 
          href="/intro" 
          className="hover:text-gray-300 font-bold" 
          onClick={() => {
            setIsMenuOpen(false);
            navigate('/intro');
          }}
        >
          简介
        </a>
        <a 
          href="/team" 
          className="hover:text-gray-300 font-bold" 
          onClick={() => {
            setIsMenuOpen(false);
            navigate('/team');
          }}
        >
          团队
        </a>
        <a 
          href="https://www.xiaohongshu.com/user/profile/6712b033000000001d020291?xsec_token=&xsec_source=pc_search" 
          className="hover:text-gray-300 font-bold" 
          onClick={() => setIsMenuOpen(false)}
          target="_blank" 
          rel="noopener noreferrer"
        >
          联系
        </a>
      </div>
    </div>
  </div>

    {/* Contact Us Icon - Hidden on mobile */}
    <div className="hidden md:flex items-center space-x-6">
      <a href="https://www.xiaohongshu.com/user/profile/6712b033000000001d020291?xsec_token=&xsec_source=pc_search" className="hover:text-gray-300">
        <img src="/images/Red_White.svg" alt="Contact Us" className="w-14 h-14" />
      </a>
    </div>
    </nav>

      {/* Hero Section */}
      <div className={`flex flex-col items-center justify-center px-4 min-h-[calc(100vh-240px)] mt-[-80px] text-center
                       transition-transform duration-500 ease-in-out
                       ${isTransitioning ? 'transform translate-y-10' : 'transform translate-y-0'}`}>
        <h1 className="text-2xl md:text-4xl font-light mb-4">我们推荐的不是海外仓</h1>
        <h2 className="text-4xl md:text-4xl font-light mb-12">而是海外仓背后为商家服务的人</h2>
        
        {/* Two Buttons Side by Side */}
        <div className="flex space-x-6">
          {users.map((user, index) => (
            <button
              key={index}
              onClick={() => handleUserClick(user.name)}
              className="bg-transparent border-2 border-white text-white px-8 py-3
                         hover:bg-white hover:text-gray-900 transition-all duration-300
                         transform hover:scale-105">
              {user.buttonText}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prelaunch;