import React from 'react';
import { useParams } from 'react-router-dom';

const businessUri = 'https://st2100000060305802.huoban.com/table_share?share_id=4300000369498191&secret=7FTQ4TtIs0I88siH18eL0WDrllIWA7TRsHnI0wTi&table_share_id=4100000026365682'
const warehouseUri = 'https://st2100000060204987.huoban.com/share/4300000369471544/7FTQ4TtIs0I88siH18eL0WDrllIWA7TRsHnI0wTi/4100000026344929/list'

const Instruction = () => {
  const { userType } = useParams();

  const content = {
    商家: {
      title: '很高兴你有兴趣与SSEEAA（海海）合作！',
      description: '海海是一个为中小商家推荐小型海外仓的平台。我们通过线下拜访或视频会议验证小型海外仓的真实性，并将通过验真的仓库发布在小红书上，供跨境商家选择。',
      note: '海海的推荐服务是永久免费的。我们不收取任何验真费或介绍费，你可以直接与商家沟通、交易。',
      features: [
        {
          title: '真实',
          description: '海海只推荐IP地址在海外的仓库实际经营者，销售代理或业务合作伙伴均不在推荐范围内。'
        },
        {
          title: '低价',
          description: '商家选择仓库时，价格至关重要。因此在服务能力相近时，海海会优先推荐价格更低的仓库。'
        },
        {
          title: '限量推荐',
          description: '由于目前商家数量有限，为了确保每个被推荐的仓库都有效益，起步阶段在每个城市我们仅推荐2~5个仓库，随着商家增多逐步推荐更多仓库。'
        }
      ],
      cooperationSteps: [
        {
          title: '报名',
          description: '点击下方链接填写报名表。'
        },
        {
          title: '验真',
          description: '预约面谈或视频会议，拍摄推荐所需的验真照片并确定报价方案。我们会按报名顺序安排验真时间'
        },
        {
          title: '推荐',
          description: '若你的城市有空位，我们会在验真后一周内，通过小红书发布推荐笔记，并将你的仓库信息纳入置顶的仓库汇总笔记。若暂无空位，会待商家增多后再行推荐。'
        },
        {
          title: '交易',
          description: '为了避免你被推销仓库系统、折扣单号的人员频繁骚扰，我们不会在小红书上直接展示你的联系方式，而会引导商家私信我们提交需求。确认意图真实后，我们会为你和商家拉群沟通。'
        }
      ]
    },
    仓库: {
      title: '成为SSEEAA（海海）合作仓库！',
      description: '加入我们的仓库网络，获得更多优质商家资源。我们提供专业的仓储对接服务，帮助您扩大业务范围。',
      note: '海海的推荐服务是永久免费的。我们不收取任何验真费或介绍费，你可以直接与商家沟通、交易。',
      features: [
        {
          title: '高效对接',
          description: '我们帮助您快速对接优质商家，省去繁琐的客户开发环节。'
        },
        {
          title: '品质保证',
          description: '我们严格筛选合作商家，确保双方合作顺畅。'
        },
        {
          title: '持续支持',
          description: '提供持续的运营支持和问题解决，助力仓库业务发展。'
        }
      ],
      cooperationSteps: [
        {
          title: '报名',
          description: '扫描海报上的二维码填写报名表。'
        },
        {
          title: '验真',
          description: '预约面谈或视频会议，拍摄推荐所需的验真照片并确定报价方案。我���会按报名顺序安排验真时间'
        },
        {
          title: '推荐',
          description: '若你的城市有空位，我们会在验真后一周内，通过小红书发布推荐笔记，并将你的仓库信息纳入置顶的仓库汇总笔记。若暂无空位，会待商家增多后再行推荐。'
        },
        {
          title: '交易',
          description: '为了避免你被推销仓库系统、折扣单号的人员频繁骚扰，我们不会在小红书上直接展示你的联系方式，而会引导商家私信我们提交需求。确认意图真实后，我们会为你和商家拉群沟通。'
        }
      ]
    }
  };

  const selectedContent = content[userType];

  return (
    <div className="min-h-screen bg-[#edf2fa] flex flex-col items-center">
      {/* Logo Header */}
      <div className="w-[92%] max-w-2xl pt-8 pb-4">
        <img 
          src="/images/logo.svg" 
          alt="SSEEAA Logo" 
          className="h-16 mx-auto"
        />
      </div>

      {/* Main Content */}
      <div className="w-[92%] max-w-2xl bg-white rounded-3xl p-4 md:p-8 border shadow-lg">
        <h1 className="text-2xl font-bold mb-6 text-center">
          {selectedContent.title}
        </h1>
        
        <p className="text-gray-600 mb-8">
          {selectedContent.description}
        </p>

        <p className="text-gray-600 mb-8">
          {selectedContent.note}
        </p>

        <h2 className="text-xl font-bold mb-4 flex items-center text-[#679ceb]">
          <img src="/images/star.svg" alt="Icon" className="h-6 w-6 mr-2" />
          谁可以被推荐
        </h2>

        <div className="space-y-6">
          {selectedContent.features.map((feature, index) => (
            <div key={index} className="border-b pb-4">
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Cooperation Process */}
        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4 flex items-center text-[#679ceb]">
            <img src="/images/rocket.svg" alt="Icon" className="h-6 w-6 mr-2" />
            立即开始合作
          </h2>
          <div className="space-y-6">
            {selectedContent.cooperationSteps.map((step, index) => (
              <div key={index} className="border-b pb-4">
                <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Button Footer */}
      <div className="w-[92%] max-w-2xl py-8 flex flex-col items-center">
        <a
          href={userType === 'warehouse' ? warehouseUri : businessUri}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-8 rounded-lg transition duration-300"
        >
          立即报名
        </a>
      </div>
    </div>
  );
};

export default Instruction;